import { RequestApi } from './api-client';

export const chunkArray = <T extends any[]>(inputArray: T, chunkSize: number): T[] => {
  return inputArray.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
};

/**
 * Helper function to transform asset urls that might start without a protocol
 */
export const transformContentfulAssetUrl = (url: string) => {
  if (url.startsWith('//')) {
    return `https:${url}`;
  }
  return url;
};
export function getUserCountryCode() {
  return RequestApi<string>({
    url: '/user-country'
  });
}

export function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

/**
 * Used to check if a product or solution should render a "NEW" tag
 * @param date createdAt date provided by Contentful and commercetools
 */
export function dateConsideredNew(date: Date) {
  const now = new Date();
  const migrationDate = new Date('2024-10-14');

  // Check if the date is after storyblok migration
  if (date < migrationDate) {
    return false;
  }

  const seconds = (now.getTime() - date.getTime()) / 1000;
  const days = seconds / 60 / 60 / 24;

  return days < 30 * 2;
}

// TODO: Shared? API uses it in /usecase-by-id
/** Move element within the given array */
export function moved(array: Array<any>, from: number, to: number, on = 1) {
  return (array = array.slice()), array.splice(to, 0, ...array.splice(from, on)), array;
}
