import React from 'react';
import {
  Cpu as ControllerIcon,
  GitPullRequest as RobotsIcon,
  Wind as PneumaticIcon,
  Camera as VisionIcon,
  Paperclip as AccessoriesIcon,
  MousePointer as AppSoftwareIcon,
  Home as HomeIcon,
  type IconProps,
  Camera,
  GitPullRequest,
  Truck,
  Coffee,
  Package,
  Droplet
} from 'react-feather';
import {
  DispensingIcon,
  EndEffectorIcon,
  LabratoryIcon,
  PaletizeIcon,
  PickAndPlaceIcon,
  ServiceIcon,
  TractorIcon
} from '~/components/rbtx-icons';
import { MobileRobotIcon } from '~/components/rbtx-icons/mobile-robot';
import { ProfilesIcon } from '~/components/rbtx-icons/profiles';
import { SafetyIcon } from '~/components/rbtx-icons/safety';

const categoryMap = {
  control_system: ControllerIcon,
  robots: RobotsIcon,
  pneumatics: PneumaticIcon,
  vision: VisionIcon,
  accessories: AccessoriesIcon,
  safety: SafetyIcon,
  end_effectors: EndEffectorIcon,
  service: ServiceIcon,
  software: AppSoftwareIcon,
  all: HomeIcon,
  pickAndPlace: PickAndPlaceIcon,
  quality: Camera,
  paletize: PaletizeIcon,
  dispensing: DispensingIcon,
  tending: GitPullRequest,
  automotive: Truck,
  food: Coffee,
  logistic: Package,
  agriculture: TractorIcon,
  laboratory: LabratoryIcon,
  gastronomy: Droplet,
  bundles: Package,
  mobile_robots: MobileRobotIcon,
  profiles_more: ProfilesIcon
};
export function getIconByCategorySlug(slug: string) {
  const Icon = categoryMap[slug as keyof typeof categoryMap];
  return (props: IconProps) => (Icon ? <Icon {...props} /> : <></>);
}
