import { useRouter } from 'next/router';
import { type TFunction, useTranslation } from 'next-i18next';
import PriceFormatter from '~/server/lib/PriceFormatter';
import { useCountryCode } from '~/lib/hooks/useCountryCode';
import Skeleton from 'react-loading-skeleton';

type PriceComponentProps = {
  price?: number; // in cents
  countryCodeOverride?: string;
};

export const PriceToText = ({
  locale,
  price,
  countryCode,
  t
}: {
  locale: string;
  price?: number | undefined;
  countryCode: string;
  t: TFunction;
}) => {
  if (price === undefined || price === PriceFormatter.ON_REQUEST) {
    return t('price.request');
  }

  if (price === PriceFormatter.FREE) {
    return t('price.free');
  }

  return PriceFormatter.format(locale, price, countryCode);
};

const Price = ({ price = -1, countryCodeOverride }: PriceComponentProps) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { locale } = router;
  const countryCode = useCountryCode();

  if (!locale) {
    throw new Error('locale is undefined');
  }

  if (!countryCode) {
    return <Skeleton />;
  }

  const formattedPrice = PriceToText({
    locale,
    price,
    countryCode: countryCodeOverride || countryCode,
    t
  });

  return <>{formattedPrice}</>;
};

export { Price };
