// eslint-disable-next-line @typescript-eslint/no-var-requires
const { RBTX_LOCALES } = require('shared');

/** @type{import("next-i18next").UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: 'DEFAULT',
    locales: ['DEFAULT', ...RBTX_LOCALES],
    localeDetection: false
  },
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  fallbackLng: 'en-GB'
};
