import { useEffect, useState, useRef, useReducer } from 'react';
import axios, { AxiosError } from 'axios';
import ApiClient, { AxiosRequestConfigWithCancel } from '~/lib/api-client';

type DataFetchState = {
  isLoading: boolean;
  isError: boolean;
  data: any;
  canceled: boolean;
  success: boolean;
};

type DataFetchAction =
  | { type: 'REQUEST_INIT'; payload?: any }
  | { type: 'REQUEST_SUCCESS'; payload: any }
  | { type: 'REQUEST_FAILURE'; payload: any }
  | { type: 'REQUEST_CANCELED'; payload: any };

const dataFetchReducer = (state: DataFetchState, action: DataFetchAction) => {
  switch (action.type) {
    case 'REQUEST_INIT':
      return { ...state, isLoading: true, isError: false, canceled: false, success: false, data: null };
    case 'REQUEST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        canceled: false,
        success: true,
        data: action.payload
      };
    case 'REQUEST_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        canceled: false,
        success: false,
        data: action.payload
      };
    case 'REQUEST_CANCELED':
      return {
        ...state,
        isLoading: false,
        isError: true,
        canceled: true,
        success: false,
        data: action.payload
      };
    default: {
      return state;
    }
  }
};
/**
 * The react hooks way to make an axios call.
 * Used in React UI code.
 */
export default function useApi(
  initialRequest: AxiosRequestConfigWithCancel | null = null,
  initialData: any = null,
  onFirstRun = false
) {
  const [request, setRequest] = useState(initialRequest);
  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    canceled: false,
    success: false,
    data: initialData
  });

  // refs will persist for the full lifetime of the component.
  const isFirstRun = useRef(!onFirstRun);

  useEffect(() => {
    const axiosCancelSource = axios.CancelToken.source();
    const getData = async () => {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }

      dispatch({ type: 'REQUEST_INIT' });

      try {
        if (!request) return;
        request.cancelTokenSource = axiosCancelSource;
        const data = await ApiClient(request);
        dispatch({ type: 'REQUEST_SUCCESS', payload: data });
      } catch (err) {
        const error = err as AxiosError;
        dispatch({ type: 'REQUEST_FAILURE', payload: error.response?.data });
        if (axios.isCancel(error)) {
          dispatch({ type: 'REQUEST_CANCELED', payload: error.response?.data });
        }
      }
    };
    getData();
    return () => axiosCancelSource.cancel('Component unmounted');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  return [state, setRequest, (data: any) => dispatch({ type: 'REQUEST_SUCCESS', payload: data })] as const;
}
