import type { GetStaticProps, GetStaticPropsContext } from 'next';
import nextI18nextConfig from '~/next-i18next.config';

export function getRBTXLocales() {
  return nextI18nextConfig.i18n.locales.filter((locale) => locale !== nextI18nextConfig.i18n.defaultLocale);
}

export function getRBTXLocaleOrFallback(locale?: string) {
  if (!isValidLocale(locale)) {
    return nextI18nextConfig.fallbackLng as string;
  }
  return locale;
}

export function isValidLocale(locale?: string): locale is string {
  return (
    locale != null && locale !== nextI18nextConfig.i18n.defaultLocale && getRBTXLocales().includes(locale)
  );
}

export function getSupportedUserLocale(locales: Array<string>) {
  // TODO: use functional approach

  const supportedLanguages = getRBTXLocales();

  for (const lang of locales) {
    if (supportedLanguages.includes(lang)) {
      return lang;
    }

    if (!lang.includes('-')) {
      continue;
    }

    const [base] = lang.split('-'); // strip region
    if (supportedLanguages.includes(base)) {
      // match later in case a different language has more priority
      if (locales.includes(base)) {
        continue;
      }

      return base;
    }
  }
}

export type GSPCtxWithLocale = GetStaticPropsContext & { locale: string; countryCode: string };

export const withRBTXLocales = <T extends Record<string, any>>(
  handler: (ctx: GSPCtxWithLocale) => ReturnType<GetStaticProps<T>>
) => {
  return (ctx: GSPCtxWithLocale): ReturnType<typeof handler> => {
    // TODO: Check if country code is valid?

    if (!isValidLocale(ctx.locale)) {
      return {
        notFound: true
      };
    }
    return handler(ctx);
  };
};
