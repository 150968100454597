import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { getRBTXLocales } from '~/lib/locales';

const SeoAlternateLinks = ({ defaultPath, canonicalPath }) => {
  const router = useRouter();
  return (
    <Head>
      {defaultPath && (
        <link rel="alternate" hrefLang="x-default" href={`${process.env.LCA_PUBLIC_URL}${defaultPath}`} />
      )}
      {canonicalPath && (
        <link rel="canonical" href={`${process.env.LCA_PUBLIC_URL}/${router.locale}${canonicalPath}`} />
      )}
      {getRBTXLocales().map((locale) => (
        <link
          key={locale}
          rel="alternate"
          href={`${process.env.LCA_PUBLIC_URL}/${locale}${canonicalPath !== '/' ? canonicalPath : ''}`}
          hrefLang={locale}
        />
      ))}
    </Head>
  );
};

SeoAlternateLinks.propTypes = {
  defaultPath: PropTypes.string.isRequired,
  canonicalPath: PropTypes.string.isRequired
};

export default SeoAlternateLinks;
