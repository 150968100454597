// function assertUnreachable(_x: never): never {
//   return null as never;
// }

import { match, P } from 'ts-pattern';

class PriceFormatter {
  static ON_REQUEST = -1;

  static FREE = 0;

  /**
   * Maps country codes to currency
   */
  static getCurrencyByCountryCode(countryCode: string): string {
    switch (countryCode) {
      case 'IN': // India
        return 'INR';
      case 'SG': // Singapore
        return 'SGD';
      case 'CH': // Switzerland
        return 'CHF';
      case 'SE': // Sweden
        return 'SEK';
      // case 'IS': // Iceland
      //   return 'ISK';
      // case 'AU': // Australia
      //   return 'AUD';
      case 'ZA': // South Africa
        return 'ZAR';
      case 'PL': // Poland
        return 'PLN';
      case 'CZ': // Czech Republic
        return 'CZK';
      // case 'NO': // Norway
      //   return 'NOK';
      case 'US': // United States
      case 'CA': // Canada
        return 'USD';
      case 'MX': // Mexico
        return 'USD';
      case 'TR': // Turkey
        return 'EUR';
      // case 'IL': // Israel
      //   return 'ILS';
      case 'GB': // Great Britain
        return 'GBP';
      case 'KR':
        return 'KRW';
      case 'CN': // China
        return 'CNY';
      case 'VN': // Vietnam
        return 'VND';
      case 'TH': // Thailand
        return 'THB';
      case 'TW': // Taiwan
        return 'NTD';
      case 'JP': // Japan
        return 'JPY';
      case 'BR': // Brazil
        return 'BRL';
      case 'ID': // Indonesia
        return 'IDR';
      case 'MY': // Malaysia
        return 'MYR';
      case 'AU':
        return 'AUD';
      case 'BA':
        return 'BAM';
      case 'BG':
        return 'BGN';
      case 'NZ':
        return 'NZD';
      case 'NO':
        return 'NOK';
      case 'RS':
        return 'RSD';
      // case 'NL': // Netherlands
      // case 'BE': // Belgium
      // case 'LU': // Luxembourg
      // case 'PT': // Portugal
      // case 'GR': // Greece
      // case 'LT': // Lithuania
      // case 'EE': // Estonia
      // case 'LV': // Latvia
      // case 'SI': // Slovenia
      // case 'SK': // Slovakia
      // case 'IE': // Ireland
      case 'AT': // Austria
      case 'DE': // Germany
      case 'FR': // France
      case 'IT': // Italy
      case 'DK': // Denmark
      case 'ES': // Spain
        return 'EUR';
      default: {
        // assertUnreachable(countryCode);
        return 'EUR';
      }
    }
  }

  /**
   * Format a number to the local currency
   * @param locale the locale to use
   * @param centAmount the amount in cents
   * @param countryCode the country code
   */
  static format(locale: string, centAmount: number, countryCode: string): string {
    if (centAmount < 1) {
      return centAmount.toLocaleString(locale, {
        style: 'currency',
        currency: PriceFormatter.getCurrencyByCountryCode(countryCode)
      });
    }

    const priceAmount = match(countryCode)
      .with(P.union('JP'), () => centAmount)
      .otherwise(() => centAmount / 100);

    if (Number.isInteger(priceAmount)) {
      return priceAmount.toLocaleString(locale, {
        style: 'currency',
        currency: PriceFormatter.getCurrencyByCountryCode(countryCode),
        minimumFractionDigits: 0
      });
    }
    return priceAmount.toLocaleString(locale, {
      style: 'currency',
      currency: PriceFormatter.getCurrencyByCountryCode(countryCode),
      minimumFractionDigits: 2
    });
  }
}

export default PriceFormatter;
